<script lang="ts" setup>
import { ref } from "vue";
import { iamType } from "@/services/api/iam.api";
import PermissionCategoriesGrid from "@/components/iam/PermissionCategoriesGrid.vue";
import RelationshipSelector from "@/components/iam/RelationshipSelector.vue";

const emits = defineEmits(["add-relationship", "remove-relationship"]);

const props = defineProps({
  preFilter: {
    type: Array,
    default: () => [],
  },
  viaAccess: undefined,
  refreshKey: undefined,
});

const currentType = iamType.PermissionCategory;

const selectorVisible = ref(false);

function onAddRelationship(selection) {
  emits("add-relationship", selection);
}

async function onRemoveRelationship(id) {
  emits("remove-relationship", id);
}
</script>

<template>
  <div>
    <permission-categories-grid :preFilter="props.preFilter" allow-remove="true" @remove-relationship="onRemoveRelationship" :viaAccess="props.viaAccess" :refreshKey="props.refreshKey">
      <template #add-relation>
        <v-btn dark class="primary mt-4" @click.stop="selectorVisible = true">
          <v-icon dark center>mdi-playlist-plus</v-icon>
        </v-btn>
      </template>
    </permission-categories-grid>
    <relationship-selector v-model="selectorVisible" @add-relationship="onAddRelationship" :domain-type="viaAccess.domain" :selection-type="currentType"> </relationship-selector>
  </div>
</template>
