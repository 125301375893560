<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getPermissionCategories } from "@/services/api/iam.api";
import GridPagination from "@/components/general/GridPagination.vue";
import { mdiTrashCanOutline } from "@mdi/js";
import useGrid27 from "@/composables/grid27.js";

const emits = defineEmits(["remove-relationship"]);

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  preFilter: {
    type: Array,
    default: () => [],
  },
  viaAccess: undefined,
  addLink: undefined,
  allowRemove: undefined,
  refreshKey: undefined,
});

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Naam", value: "name" },
  { text: "Omschrijving", value: "description" },
  { text: "", value: "delete-link", sortable: false, width: 20, showOnAllowDelete: true },
];

const headers = computed(() => {
  if (props.allowRemove) {
    return _headers.filter((x) => !x.showOnAllowDelete || x.showOnAllowDelete == true);
  } else {
    return _headers.filter((x) => !x.showOnAllowDelete);
  }
});

const filterData = ref([]);

watch(
  () => props.refreshKey,
  () => {
    fetchFirstPage();
  }
);

watch(
  () => filterData.value,
  () => {
    console.log("PermissionCategoriesGrid filterData: ", filterData.value);
    fetchFirstPage();
  },
  { deep: true }
);

watch(
  () => props.preFilter,
  () => {
    filterData.value = [...props.preFilter];
  },
  { immediate: true, deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  const response = await getPermissionCategories(pageNumber0, pageSize, sortBy, sortDesc, filterData.value);
  return response;
}

function onRemoveLink(id) {
  emits("remove-relationship", id);
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-filters align-items-start">
      <v-spacer></v-spacer>
      <v-col cols="auto" class="text-right">
        <v-btn v-if="props.addLink" dark class="primary mt-4" :to="props.addLink">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-right">
        <slot name="add-relation"></slot>
      </v-col>
    </v-row>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'IAM-PermissionCategoryDetails', params: { permissionCategoryId: item.id } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.delete-link`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="onRemoveLink(item.id)" icon small plain v-on="on">
              <v-icon small>{{ mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Verwijder koppeling</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
